<template>
  <div class="form-group">
    <multiselect
      v-model="selected"
      :track-by="trackBy ? trackBy : ''"
      :label="optionLabel ? optionLabel : ''"
      :options="options"
      :close-on-select="closeOnSelect ? closeOnSelect : false"
      :option-height="40"
      :showLabels="false"
      :searchable="searchable ? searchable : false"
      :multiple="true"
      :placeholder="placeholder ? placeholder : 'Selecionar'"
      class="multiple"
      :class="{ 'with-border': !borderless, borderless }"
      :limit="limit ? limit : 10"
      openDirection="bottom"
    >
      <template slot="caret">
        <div class="chevron">
          <ChevronDown />
        </div>
      </template>
      <template slot="selection" slot-scope="{ values }">
        <div v-bind:class="{ selectedOptions: values.length > 0 }">
          <span v-if="allSelected()" class="label-text">
            {{ allSelectedLabel }}
          </span>
          <span v-else-if="optionLabel">
            <span v-for="(val, index) in values" :key="index">
              {{ val[optionLabel] }}
            </span>
          </span>
          <span v-if="hasReachedLimit">
            {{ limitedText }}
          </span>
        </div>
      </template>
      <template slot="noOptions"> Nenhuma opção </template>
      <template slot="noResult"> Nenhum resultado </template>
    </multiselect>
  </div>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: { ChevronDown },
  props: {
    value: Array,
    trackBy: String,
    optionLabel: String,
    options: Array,
    placeholder: String,
    searchable: Boolean,
    closeOnSelect: Boolean,
    allSelectedLabel: {
      default: 'Todos',
      type: String
    },
    borderless: {
      default: false,
      type: Boolean
    },
    limit: Number
  },

  data() {
    return {
      selected: this.value,
      canChangeSelected: true
    }
  },
  computed: {
    hasReachedLimit() {
      return (
        this.limit && this.selected.length > this.limit && !this.allSelected()
      )
    },
    limitedText() {
      return ` + ${this.selected.length - this.limit}`
    }
  },
  methods: {
    allSelected() {
      return !(this.selected.length < this.options.length)
    },
    changeSelected(value) {
      this.$emit('input', value)
      this.$emit('onChange', value)
    },
    handleSelectAll(newValue, oldValue) {
      if (this.options.some(option => option?.value === null))
        return (
          !oldValue.some(el => el?.value === null) &&
          newValue.some(el => el?.value === null)
        )

      return oldValue.indexOf(null) === -1 && newValue.indexOf(null) >= 0
    },

    handleDeselectAll(newValue, oldValue) {
      if (this.options.some(option => option?.value === null))
        return (
          oldValue.some(el => el?.value === null) &&
          !newValue.some(el => el?.value === null)
        )

      return oldValue.indexOf(null) >= 0 && newValue.indexOf(null) === -1
    },
    handleRemoveAllOption() {
      if (this.options.some(option => option?.value === null))
        return this.selected.filter(el => el?.value !== null)

      return this.selected.filter(el => el !== null)
    }
  },

  watch: {
    selected: function (newValue, oldValue) {
      if (this.canChangeSelected) {
        if (this.handleSelectAll(newValue, oldValue)) {
          this.canChangeSelected = false
          this.selected = this.options.map(el => {
            return el
          })
          this.changeSelected(null)
          setTimeout(() => {
            this.canChangeSelected = true
          }, '200')
        } else if (this.handleDeselectAll(newValue, oldValue)) {
          this.canChangeSelected = false
          this.selected = []
          this.changeSelected([])
          setTimeout(() => {
            this.canChangeSelected = true
          }, '200')
        } else if (this.allSelected()) {
          this.changeSelected(this.options)
        } else {
          this.canChangeSelected = false
          this.selected = this.handleRemoveAllOption()
          this.changeSelected(this.selected)
          setTimeout(() => {
            this.canChangeSelected = true
          }, '200')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.selectedOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 100%;
  overflow: hidden;
  text-align: start !important;
  height: 50% !important;
}
.label-text {
  max-width: 20ch;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.borderless {
  border: none !important;
}
</style>
